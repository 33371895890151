import React from 'react';
import Layout from '../components/Layout/Layout';
import HomeLayout from '../components/HomeLayout/HomeLayout';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from "gatsby-image"
const Services = () => {
    
    const data = useStaticQuery(graphql`
    query {
      ServicePage: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "ServicePage" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    `)
    return (
        <Layout>
            <div className="HomeLayout1">
                {
                    data.ServicePage.nodes.map(img => {
                      var name = img.name.split(" ")
                        return (<Link to={`/Services/${name[2]}`} key={img.id}>
                        <Img fluid={img.childImageSharp.fluid} alt="Services images" />
                      </Link>)
                    })
                }
            </div>
        </Layout>
    );
};

export default Services;